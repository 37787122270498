import React from 'react'
import { useRouter } from 'next/router'
import Image from 'next/image'
import { Swiper, SwiperSlide } from 'swiper/react'
import styled from 'styled-components'
import isEmpty from 'lodash/isEmpty'
import { useRecoilValue } from 'recoil'
import { screen } from '@pig-common/utils/responsive'
import { TextLink } from '@pig-common/components/Button'
import { GameCompanyTypes } from '@pig-common/models/shared-types'
import gameBrandStateAtom from '@pig-common/recoils/modules/game-brand'
import { notification } from '@pig-frontend/uikit'
import { pageLink } from '@pig-casino/constants/page-name'
import { gamesApi } from '@pig-casino/api'
import useListGame from '@pig-casino/hooks/useListGame'
import { Autoplay, Navigation, Pagination } from 'swiper'
import { IGameItem } from '@pig-casino/types/Game.type'

export type BannerCarouselDataType = {
  title: string
  imageSrc: string
  slug: string
  gameCode: string
  refType: string
}

const CarouselButton = styled.div`
  width: 100%;
  position: absolute;
  bottom: 32px;
  text-align: center;
  z-index: 2;
`
const CarouselButtonText = styled(TextLink)`
  width: min-content;
  pointer-events: all;
  font-size: 20px;
  line-height: 20px;
  color: white !important;
  margin: 10px auto;
`
const MainBannerSwiper = styled(Swiper)`
  margin-bottom: 12px;
  background-color: ${({ theme }) => theme['color-greyscale-200']};
  overflow: hidden;
  width: 100%;
  height: 324px;
  @media ${screen.md} {
    height: 620px;
  }
  @media ${screen.lg} {
    height: 390px;
  }
  @media ${screen.xl} {
    height: 490px;
  }
  @media only screen and (min-width: 1920px) {
    height: 690px;
  }
`
const SwiperPagination = styled.div`
  position: absolute;
  bottom: 8px;
  left: 0;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  gap: 10px;
  margin: 12px auto;
  z-index: 2;
  span {
    background-color: var(--color-greyscale-100);
    width: 10px;
    height: 10px;
    margin: 0 4px;
  }
`

const BannerCarousel = ({ isMobileScreen }: { isMobileScreen: boolean }) => {
  const gameBrandState = useRecoilValue(gameBrandStateAtom)
  const router = useRouter()
  const { data: highlightGame = [] } = useListGame({
    key: [gamesApi.key.highlight],
    params: { filterType: 'highlight' },
  })
  const [currentSlide, setSlide] = React.useState<number>(0)
  const [errorImage, setErrorImage] = React.useState<string[]>([])
  const swiperContainer = React.useRef(null)
  const isGameProviderOpen = React.useMemo(() => {
    if (!Array.isArray(highlightGame) || isEmpty(highlightGame)) return false
    const brand =
      gameBrandState.obj[
        (highlightGame[currentSlide].gameProvider || 'NONE') as GameCompanyTypes
      ]
    return !brand?.isUnderConstruction || false
  }, [highlightGame, currentSlide, errorImage])
  const onSlideChanged = (e: any) => {
    return e.realIndex >= 0 && setSlide(e.realIndex)
  }
  const validImage = (game: IGameItem) => {
    return !errorImage.find(
      (url) => `${game.lobbyBannerUrl}${isMobileScreen ? '-sm' : ''}` === url,
    )
  }
  const displayBanner = React.useMemo(() => {
    const result = highlightGame.filter((game: IGameItem) => {
      const hasImage = !isEmpty(game.lobbyBannerUrl)
      const isErrorImage = errorImage.find((url) => game.lobbyBannerUrl === url)
      return hasImage && !isErrorImage
    })
    return result
  }, [highlightGame, errorImage, isMobileScreen])
  const renderSlider = React.useMemo(() => {
    if (isEmpty(displayBanner)) return null
    return (
      <>
        {Array.isArray(displayBanner) &&
          displayBanner
            .filter(validImage)
            .map(
              (
                {
                  gameName: title,
                  lobbyBannerUrl,
                  gameId: gameCode,
                  gameProvider: refType,
                },
                i,
              ) => {
                const iFileExtension = lobbyBannerUrl.lastIndexOf('.')
                const imageSrc = `${lobbyBannerUrl.slice(0, iFileExtension)}${
                  isMobileScreen ? '-sm' : ''
                }${lobbyBannerUrl.slice(iFileExtension)}`
                return (
                  <SwiperSlide key={`${refType}-${gameCode}`}>
                    <Image
                      priority
                      className="ps-banner-carousel__image"
                      fill
                      src={imageSrc}
                      alt={`${title} Banner`}
                      style={{
                        objectFit: 'cover',
                      }}
                      quality={75}
                      onError={() =>
                        setErrorImage((prev) => [...prev, lobbyBannerUrl])
                      }
                    />
                  </SwiperSlide>
                )
              },
            )}
      </>
    )
  }, [displayBanner])
  const appendReadmoreButton = React.useCallback(() => {
    if (isEmpty(displayBanner)) return null
    return (
      <CarouselButton>
        <CarouselButtonText
          onClick={() => {
            if (isGameProviderOpen) {
              router.push(
                `${pageLink.game}/${displayBanner[
                  currentSlide
                ].gameName.replace(/ /g, '-')}`,
              )
            } else {
              const toast = notification.warning
              toast({
                message: `เกม ${displayBanner[currentSlide].gameName} ปิดปรับปรุงชั่วคราว`,
              })
            }
          }}
        >
          <div
            style={
              isGameProviderOpen
                ? undefined
                : { opacity: 0.4, cursor: 'default' }
            }
          >
            รายละเอียดเกม
          </div>
        </CarouselButtonText>
      </CarouselButton>
    )
  }, [displayBanner, currentSlide])
  if (!isEmpty(displayBanner))
    return (
      <section className="ps-banner-game-section">
        {appendReadmoreButton()}
        {
          <MainBannerSwiper
            modules={[Navigation, Pagination, Autoplay]}
            slidesPerView={1}
            navigation
            pagination={{
              enabled: true,
              clickable: true,
              bulletClass: 'swiper-pagination-bullet',
              el: swiperContainer.current,
            }}
            autoplay={{
              delay: 5000,
              waitForTransition: true,
            }}
            onLoad={() => setSlide(0)}
            onSlideChange={(swiper) => onSlideChanged(swiper)}
            loop
          >
            {renderSlider}
            <SwiperPagination ref={swiperContainer} />
          </MainBannerSwiper>
        }
      </section>
    )
}

export default BannerCarousel
