import { useMemo, useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination, Autoplay } from 'swiper'
import { RightOutlined } from '@ant-design/icons'
import useCampaign from '@pig-casino/hooks/useCampaign'
import usePromotions from '@pig-casino/hooks/usePromotions'
import { useRouter } from 'next/router'
import { promotionImageAssetUrl } from '@pig-casino/utils/promotion-image-url'
import { pageLinkCommon } from '@pig-common/constants/page-name'
import { IPromotionsItem } from '@pig-casino/types/Promotions.type'
import styles from './index.module.scss'
import styled from 'styled-components'
import isEmpty from 'lodash/isEmpty'
import Link from 'next/link'
import Images from 'next/image'
import { Section } from '@pig-frontend/uikit/lib/section'
import { ICampaignsResultData } from '@pig-casino/types/Campaign.type'

interface IPromotionSection {
  isMobile?: boolean
}

const HeaderLink = styled(Link)`
  font-size: 20px;
  display: flex;
  svg {
    font-size: 13px;
  }
`
const PromotionSwiper = styled(Swiper)`
  border-radius: 8px;
  overflow: clip;
`
const SwiperArrow = styled(RightOutlined)`
  position: absolute;
  top: calc(50% - 20px);
  right: 16px;
  padding: 8px;
  max-width: min-content;
  color: white;
  text-shadow: 0 0 5px red;
  z-index: 1;
  filter: drop-shadow(0px 0px 5px black);
  &.arrow-left {
    transform: rotate(180deg);
    left: 16px;
  }
`
const SwiperPagination = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  gap: 10px;
  margin-top: 8px;
  margin-bottom: 18px;
  span {
    background-color: var(--color-primary-1);
    width: 10px;
    height: 10px;
    margin: 0px;
  }
`

/**
 * Every page that imported this PromotionSection component, Must separate page to mobile and desktop
 * whenever promotion need SEO or using server-side props or static build.
 * These don't know window size before client mounted. Make promotion layout shift.
 *
 * Unless we don't want SEO from this component anymore, you can delete it.
 */
export default function PromotionSection({ isMobile }: IPromotionSection) {
  const router = useRouter()
  const [errorImage, setErrorImage] = useState<string[]>([])
  const refSwiperPagination = useRef(null)
  const { data: campaigns = [] } = useCampaign({
    params: { productType: 'PIG_BET' },
  })
  const { data: promotions = [] } = usePromotions({
    params: { productType: 'PIG_BET' },
  })

  const handleClick = (isCampaign, code) => {
    if (isCampaign) {
      router.push(`${pageLinkCommon.campaigns}/${code}`)
    } else {
      router.push(`${pageLinkCommon.promotions}/${code}`)
    }
  }
  const allPromotions = useMemo(() => {
    if (Array.isArray(promotions) && Array.isArray(campaigns)) {
      return [...promotions, ...campaigns].filter(
        (promo) => !errorImage.includes(promo.code) && promo.isActive,
      )
    }
    return []
  }, [promotions, campaigns, errorImage])
  const renderSlider = useMemo(() => {
    if (isEmpty(allPromotions)) return null
    const fileNameSuffix = isMobile ? 'xs-sm.webp' : 'md-lg-xl.webp'

    return (
      <>
        {allPromotions.map((item: IPromotionsItem | ICampaignsResultData) => {
          const type = item?.type === 'campaign' ? 'campaigns' : 'promotions'
          const code = item?.code || '--'
          const promotionImageUrl = promotionImageAssetUrl(
            type,
            code,
            `banner-${fileNameSuffix}`,
          )
          return (
            <SwiperSlide key={code}>
              <Link
                href="#"
                onClick={(e) => {
                  e.preventDefault()
                  router.push(`/${type}/${code}`)
                }}
                key={`${type}_${code}`}
              >
                <div className="ps-promotions-section__image-container">
                  <Images
                    priority
                    src={promotionImageUrl}
                    width={isMobile ? 1050 : 492}
                    height={isMobile ? 235 : 321}
                    layout="responsive"
                    alt="banner"
                    quality={75}
                    onError={() => {
                      setErrorImage((prev) => [...prev, code])
                    }}
                    onClick={() =>
                      handleClick(item.type === 'campaign', item.code)
                    }
                  />
                </div>
              </Link>
            </SwiperSlide>
          )
        })}
      </>
    )
  }, [allPromotions, isMobile])

  const headerLinkButton = useMemo(() => {
    return (
      Array.isArray(allPromotions) &&
      allPromotions.length > 0 && (
        <HeaderLink
          data-testid="promotions-section__link"
          href={pageLinkCommon.promotions}
        >
          <span>
            {`ทั้งหมด ${
              allPromotions.length ? `(${allPromotions.length})` : ''
            }`}
          </span>
          <RightOutlined />
        </HeaderLink>
      )
    )
  }, [allPromotions])

  if (!isEmpty(allPromotions)) {
    return (
      <Section
        title="โปรโมชั่น"
        className={styles.container}
        sectionClassName={styles['promotion-section']}
        extra={headerLinkButton}
        sectionId={'promotion-slide'}
      >
        <PromotionSwiper
          className={styles.swiper}
          modules={[Navigation, Pagination, Autoplay]}
          initialSlide={1}
          navigation={{
            nextEl: '.arrow-right',
            prevEl: '.arrow-left',
          }}
          pagination={{
            enabled: true,
            clickable: true,
            bulletClass: 'swiper-pagination-bullet',
            el: refSwiperPagination?.current
              ? refSwiperPagination.current
              : undefined,
          }}
          autoplay={{
            delay: 7000,
            disableOnInteraction: true,
            waitForTransition: true,
          }}
          loop
        >
          {renderSlider}
        </PromotionSwiper>
        <SwiperArrow className="arrow-left" />
        <SwiperArrow className="arrow-right" />
        <SwiperPagination ref={refSwiperPagination} />
      </Section>
    )
  }
}
