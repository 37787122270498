import { ReactNode, useState, useEffect, useRef, useMemo } from 'react'
import { useRouter } from 'next/router'
import cx from 'classnames'
import {
  Layout as UIKitLayout,
  ThemeTypes,
  NavigationBar,
  Announcement,
  Modal,
  usePrevious,
} from '@pig-frontend/uikit'
import {
  logoImage,
  HamburgerSignoutIcon,
  HamburgerUnverifiedIcon,
  HamburgerVerifiedIcon,
  logoEventChinese,
} from '@pig-casino/constants/images'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { useMediaQuery } from '@pig-frontend/uikit'
import useProfile from '@pig-common/hooks/useProfile'
import { useAuth } from '@pig-common/hooks/useAuth'
import {
  backButtonStateCommon,
  chatChannelStateCommon,
  profileStateCommon,
  registerStateCommon,
  inboxStateCommon,
  authStateCommon,
} from '@pig-common/recoils'
import Footer from '@pig-casino/components/footer'
import useLogout from '@pig-common/hooks/useLogout'
import { pageLinkCommon } from '@pig-common/constants/page-name'
import { useInbox } from '@pig-common/hooks/useInbox'
import dynamic from 'next/dynamic'
import Balance from '@pig-casino/components/balance'
import Head from 'next/head'
import { WEB_URL } from '@pig-casino/constants/env'
import useAppSwitcher from '@pig-casino/hooks/useAppSwitcher'
import useGuild from '@pig-common/hooks/useGuild'
import useDailySpinCheckIn from '@pig-common/hooks/useDailySpinCheckIn'
import useVIPStatus from '@pig-common/hooks/useVipStatus'
import useGetReadFlag from '@pig-common/hooks/useGetReadFlag'
import { UserProfile, UserExpBar } from '@pig-common/components/User'
import { headerMenus } from './config/header-menus'
import { navigationBarItems } from './config/navigation-bar'
import { useNotification } from '@pig-common/hooks/useNotification'
import { hamburgerMenus } from './config/hamburger-menus'
import styles from './index.module.scss'

const PageProgress = dynamic(
  () => import('@pig-frontend/uikit').then((m) => m.PageProgress),
  { ssr: false },
)
const FloatingMenu = dynamic(
  () => import('@pig-casino/components/floating-menu'),
  { ssr: false },
)
const ChatHeader = dynamic(
  () => import('@pig-common/components/Chat/ChatHeader'),
  { ssr: false },
)
const ChatIcon = dynamic(
  () => import('@pig-common/components/Chat/ChatButton'),
  { ssr: false },
)
const ChatBox = dynamic(() => import('@pig-common/components/Chat/ChatBox'), {
  ssr: false,
})
const PigHunt = dynamic(() => import('@pig-common/components/PigHunt'), {
  ssr: false,
})
const { Header, Body } = UIKitLayout

export interface HeaderProps {
  theme?: ThemeTypes
  children?: ReactNode
}

const pageIgnoreFooterLayout = [
  pageLinkCommon.champion,
  pageLinkCommon.notifications,
]
const navbarBgColorMapper = {
  [pageLinkCommon.affiliate]: styles['affiliate-container'],
}
const skipLayoutPathnames = [pageLinkCommon.maintenance]

export default function Layout({ theme, children }: HeaderProps) {
  const router = useRouter()
  const [modalCookieSyncTabVisible, setModalCookieSyncTabsVisible] =
    useState<boolean>(false)
  const isMD = useMediaQuery('md')
  const [isDomMounted, setDomMount] = useState<boolean>(false)
  const auth = useRecoilValue(authStateCommon.authState)
  const previousCfid = usePrevious(auth?.cfid)
  const _isAuthenticated = useAuth()
  const isAuthenticated = useMemo(
    () => isDomMounted && _isAuthenticated,
    [isDomMounted, _isAuthenticated],
  )
  const { data: profile, refetch: getProfile } = useProfile({ enabled: false })
  const { data: guildData, refetch: getGuildData } = useGuild({
    enabled: false,
  })
  const { refetch: getVIPStatus } = useVIPStatus({
    enabled: false,
  })
  const isFetchedProfile = useRef<boolean>(false)
  const { logout } = useLogout()
  const { callNotification } = useNotification({
    userUID: auth.userUID,
  })
  const [, { handleSwitch }] = useAppSwitcher()
  const logo = {
    imgSrc: logoImage,
    decor: {
      enable: true,
      url: logoEventChinese,
      size: {
        x: 36,
        y: 36,
      },
      position: {
        x: '12px',
        y: '-2px',
      },
    },
  }
  const canonicalUrl =
    WEB_URL?.toLowerCase() + '/' + decodeURI(router.asPath?.substring(1))
  const isAuthenticationPage = ['/login', '/register', '/reset-pin'].includes(
    router.pathname,
  )
  const setRegisterStep = useSetRecoilState(
    registerStateCommon.setRegisterStepState,
  )
  /** TODO#1 Legacy code from pig-common; improve me in the future; we shouldn't keep function in global state */
  const [backButton, setBackButton] = useRecoilState(
    backButtonStateCommon.backButtonState,
  )
  const [isChatPanelVisible] = useRecoilState(
    chatChannelStateCommon.chatPanelVisibleState,
  )
  const { connectInboxSocket } = useInbox()
  const isProfileVerified = useRecoilValue(
    profileStateCommon.isProfileVerifiedState,
  )
  const { mutate: checkInDailySpin } = useDailySpinCheckIn()

  /* -------- Re-render Read flag of hamburger menus -------- */
  const { data: dailyQuestReadFlag, refetch: getDailyQuestReadFlag } =
    useGetReadFlag({
      enabled: false,
      params: { fieldName: 'DAILY_QUESTS' },
    })
  const resolvedHamburgerMenus = useMemo(() => {
    return hamburgerMenus.map((item) => {
      return {
        ...item,
        hasBadge: item.key === 'daily-quest' && !dailyQuestReadFlag?.flagStatus,
      }
    })
  }, [dailyQuestReadFlag])
  /* ------------------------------- */

  /* -------- Inbox session -------- */
  const [bottomItems, setBottomItems] = useState(navigationBarItems)
  const allInbox = useRecoilValue(inboxStateCommon.allInboxSelector)

  useEffect(() => {
    const items = bottomItems
    if (allInbox?.sumUnread > 0) {
      items[4].showBadge = true
    } else {
      items[4].showBadge = false
    }
    setBottomItems(items)
  }, [allInbox, allInbox?.sumUnread])
  /* ------------------------------- */

  useEffect(() => {
    /**
     *  @NOTE: Should fetch profile once and prevent fetching profile
     *  with old cookie when come from come from app switching or /authenticate page
     */
    if (
      isAuthenticated &&
      router.pathname !== pageLinkCommon.authenticate &&
      !isFetchedProfile.current
    ) {
      getProfile()
      getVIPStatus()
      getGuildData()
      if (previousCfid === auth?.cfid) {
        isFetchedProfile.current = true
      }
    }
    // Reset first fetch when logged out
    if (!isAuthenticated) {
      isFetchedProfile.current = false
    }
    if (previousCfid !== auth?.cfid) {
      isFetchedProfile.current = false
    }
  }, [router.pathname, isAuthenticated, previousCfid, auth?.cfid])

  useEffect(() => {
    if (isAuthenticated) {
      getDailyQuestReadFlag()
    }
  }, [router.pathname, isAuthenticated])

  useEffect(() => {
    // Sync tabs notification
    const channel = new BroadcastChannel('cookiesSyncTabs')
    channel.onmessage = (event) => {
      if (event?.data === 'UPDATE_TAB') {
        if (router.pathname !== pageLinkCommon.authenticate) {
          setModalCookieSyncTabsVisible(true)
        }
      }
    }
    return () => {
      if (channel) {
        channel?.close()
      }
    }
  }, [router.pathname])

  useEffect(() => {
    setDomMount(true)
  }, [])

  useEffect(() => {
    // Enable body scroll after force removed chat element when closed chat
    if (!isChatPanelVisible) {
      Object.assign(document.body.style, {
        overflowY: 'auto',
        touchAction: 'auto',
      })
    }
  }, [isChatPanelVisible])

  useEffect(() => {
    if (isAuthenticated) {
      callNotification()
      connectInboxSocket()
    }
  }, [isAuthenticated])

  useEffect(() => {
    if (isAuthenticated) {
      checkInDailySpin()
    }
  }, [isAuthenticated])

  const handleBackBtnAction = () => {
    router.back()
  }

  useEffect(() => {
    /** TODO#1 Legacy code from pig-common; improve me in the future; we shouldn't keep function in global state */
    if (
      router.pathname === pageLinkCommon.lobby ||
      router.pathname === pageLinkCommon.lobbyMobile
    ) {
      setBackButton({ onBack: null })
    }
  }, [router.pathname])
  /** TODO#1 Legacy code from pig-common; improve me in the future; we shouldn't keep function in global state */
  const handleRegisterBackStep = () => {
    if (backButton?.onBack === -1) {
      setRegisterStep(undefined)
      router.back()
    }
    if (backButton?.onBack && typeof backButton?.onBack !== 'number') {
      backButton?.onBack()
      setBackButton({ onBack: null })
    } else {
      setRegisterStep(undefined)
      router.back()
    }
  }

  if (skipLayoutPathnames.includes(router.pathname)) {
    return <>{children}</>
  }

  return (
    <>
      <Head>
        <link rel="canonical" href={canonicalUrl} />
      </Head>

      <PageProgress options={{ showSpinner: false }} />

      <UIKitLayout theme={theme}>
        <Header
          logo={logo}
          isAuthenticated={isAuthenticated}
          menus={
            !isChatPanelVisible
              ? headerMenus({
                  handleSwitch,
                  hasNotiUnread: allInbox?.sumUnread > 0,
                })
              : []
          }
          chat={{
            icon: <ChatIcon />,
            isVisible: isChatPanelVisible,
          }}
          backBtnAction={
            isAuthenticationPage ? handleRegisterBackStep : handleBackBtnAction
          }
          hamburger={{
            profile: {
              avatarName: profile?.avatar,
              profileLink: pageLinkCommon.profile.home,
              userName: profile?.displayName || profile?.playerUsername,
              isVerified: isProfileVerified,
              verifyText: isProfileVerified
                ? 'ยืนยันตัวตนแล้ว'
                : 'ยืนยันตัวตนเพื่อรับฟรีเครดิต',
              verifyIcon: isProfileVerified ? (
                <HamburgerVerifiedIcon />
              ) : (
                <HamburgerUnverifiedIcon />
              ),
              verifyLink: pageLinkCommon.profile.setKYC,
              vip: <UserProfile noDescription />,
              exp: <UserExpBar />,
            },
            logout: {
              icon: <HamburgerSignoutIcon />,
              onClick: logout,
            },
            menus: resolvedHamburgerMenus,
            balanceNode: <Balance />,
            guild: guildData?.myGuild?.info,
            vip: {
              link: pageLinkCommon.profileDetails,
            },
          }}
          className={cx(navbarBgColorMapper[router.pathname])}
        />

        {isChatPanelVisible && <ChatBox />}
        <ChatHeader />

        {/* Sync tabs modal */}
        <Modal
          open={modalCookieSyncTabVisible}
          title="การเข้าสู่ระบบ"
          okText="รีเฟรช"
          onOk={() => typeof window !== 'undefined' && window.location.reload()}
          centered
          closable={false}
          className={styles['modal-cookie-sync-tabs']}
          okButtonProps={{ ghost: true }}
        >
          <span>พบการเข้าสู่ระบบบซ้ำกรุณารีเฟรชใหม่</span>
        </Modal>
        <Announcement
          show={
            !!auth.customerCode &&
            router.pathname.replace('/mobile', '') === '/lobby'
          }
        />
        <Body>{children}</Body>

        {isAuthenticated && <FloatingMenu />}

        {!pageIgnoreFooterLayout.includes(router.pathname) && <Footer />}

        {!isMD && isAuthenticated && <NavigationBar items={bottomItems} />}

        <PigHunt />
      </UIKitLayout>
    </>
  )
}
