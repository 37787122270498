import { useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import { Tabs } from 'antd'
import { Image, Button } from '@pig-frontend/uikit'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Swiper as SwiperTypes } from 'swiper/types'
import { Autoplay } from 'swiper'
import isEmpty from 'lodash/isEmpty'
import { pageLink } from '@pig-casino/constants/page-name'
import { tabsItems } from './config/tabs'
import { gameSlides } from './config/slides'
import styles from './index.module.scss'

export default function GameCarousel() {
  const [activeTab, setActiveTab] = useState('baccarat')
  const slideGroupRef = useRef([])
  const slideInterval = useRef(null)
  const [domLoaded, setDomLoaded] = useState(false)
  const router = useRouter()
  const [swiper, setSwiper] = useState<SwiperTypes>(null)

  useEffect(() => {
    // react-swiper has a problem about SSR hydration
    setDomLoaded(true)
  }, [])

  useEffect(() => {
    // Active tab by detecting half screen of slide's x-position
    if (!slideInterval.current) {
      slideInterval.current = setInterval(() => {
        if (!isEmpty(slideGroupRef.current)) {
          slideGroupRef.current.map((slide) => {
            const x = slide?.getBoundingClientRect()?.x
            const xPercentage = ((x - (170 + 16) * 6) / window.innerWidth) * 100
            if (xPercentage <= 60) {
              const gameType = slide?.getAttribute('data-gametype')
              setActiveTab(gameType)
            }
          })
        }
      }, 1000)
    }
    return () => {
      if (slideInterval.current) {
        clearInterval(slideInterval.current)
        slideInterval.current = null
      }
    }
  }, [])

  const goToSlide = (gameType: string) => {
    const i = gameSlides.findIndex((game) => game.gameType === gameType)
    swiper.slideToLoop(i)
    setActiveTab(gameSlides[i]?.gameType)
  }

  const goToGameDetail = (gameName: string) => {
    router.push({
      pathname: `${pageLink.game}/${gameName?.replace(/[ ]/g, '-')}`,
    })
  }

  return (
    <div className={styles.container}>
      <div className={styles['tabs-wrapper']}>
        <Tabs
          activeKey={activeTab}
          centered
          onTabClick={goToSlide}
          items={tabsItems}
          className={styles.tabs}
        />
      </div>

      {domLoaded && !isEmpty(gameSlides) && (
        <Swiper
          onSwiper={setSwiper}
          spaceBetween={10}
          slidesPerView={1}
          breakpoints={{
            375: {
              slidesPerView: 2,
              spaceBetween: 28,
            },
            468: {
              slidesPerView: 3,
              spaceBetween: 108,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 5,
              spaceBetween: 20,
            },
          }}
          direction="horizontal"
          centeredSlides
          loop
          grabCursor
          modules={[Autoplay]}
          autoplay={{
            delay: 1500,
            disableOnInteraction: false,
          }}
          onSlideChange={(swiper) => {
            setActiveTab(gameSlides[swiper.realIndex]?.gameType)
          }}
        >
          {gameSlides.map((slide) => (
            <SwiperSlide key={slide.gameId}>
              <div className={styles['demo-btn-container']}>
                <Button
                  type="default"
                  className={styles['demo-btn']}
                  onClick={() => goToGameDetail(slide?.name)}
                >
                  รายละเอียด
                </Button>
                <Image
                  src={slide.image}
                  alt={slide.name}
                  className={styles.img}
                  width={174}
                  height={359}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </div>
  )
}
