import { useEffect, useState } from 'react'
import cx from 'classnames'
import { Image } from '@pig-frontend/uikit'
import { partnersConfig, PartnerKeysType } from './config'
import { useRouter } from 'next/router'
import isEmpty from 'lodash/isEmpty'
import styles from './index.module.scss'
import styled from 'styled-components'

interface IPartnerSectionProps {
  title?: string
  className?: string
  imageWrapperClassName?: string
  sectionClassName?: string
  /** Partner image will be white and good for colored BG (not white BG) */
  isWhiteImage?: boolean
  blackListKey?: PartnerKeysType[]
}

const PartnerHeader = styled.h5`
  width: 100%;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 18px;
`
export default function PartnerSection({
  title,
  className,
  imageWrapperClassName,
  sectionClassName,
  isWhiteImage,
  blackListKey = [],
}: IPartnerSectionProps) {
  const router = useRouter()
  const [partners, setPartners] =
    useState<typeof partnersConfig>(partnersConfig)

  useEffect(() => {
    if (!isEmpty(blackListKey)) {
      const whiteListPartners = partnersConfig.filter(({ key }) => {
        return !blackListKey.includes(key)
      })
      setPartners(whiteListPartners)
    }
  }, [partnersConfig, blackListKey])

  return (
    <>
      <PartnerHeader>{`${title}` || 'พาร์ทเนอร์'}</PartnerHeader>
      <div className={cx(styles.container, className)}>
        {partners.map(({ key, image, whiteImage, link }) => (
          <div
            key={key}
            className={cx(styles.item, imageWrapperClassName)}
            {...(link
              ? { role: 'button', onClick: () => router.push(link) }
              : {})}
          >
            <Image
              src={isWhiteImage ? whiteImage : image}
              alt={key}
              className={cx(styles.img, 'partner-img')}
            />
          </div>
        ))}
      </div>
    </>
  )
}
